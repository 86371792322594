<template>
  <introduction-template
    :service-name="$route.name"
    :title="$t(`button['automatic image understanding']`)"
    :description="$t(`services.aiu.description`)"
    :particle-options="particleOptions"
    :features="servicesCards"
    service-abbrev="AIU"
  >
    <template slot="banner">
      <banner class="animate-svg-blocks" />
    </template>
  </introduction-template>
</template>
<script>
import iconCLS from '@/assets/images/icons/services/introduction/aiu/cls.svg';
import iconQUA from '@/assets/images/icons/services/introduction/aiu/qua.svg';
import iconOBJ from '@/assets/images/icons/services/introduction/aiu/obj.svg';
import iconOCR from '@/assets/images/icons/services/introduction/aiu/ocr.svg';
import iconSEG from '@/assets/images/icons/services/introduction/aiu/seg.svg';
import iconTAG from '@/assets/images/icons/services/introduction/aiu/tag.svg';
import iconLAD from '@/assets/images/icons/services/introduction/aiu/lad.svg';
import iconCBT from '@/assets/images/icons/services/introduction/aiu/cbt.svg';
import iconCST from '@/assets/images/icons/services/introduction/aiu/cst.svg';
import iconNSFW from '@/assets/images/icons/services/introduction/aiu/nsfw.svg';
import Banner from '../../components/BannerBlocks/AIU.vue';
import IntroductionTemplate from '../../components/IntroductionTemplate/Index.vue';

export default {
  components: { IntroductionTemplate, Banner },
  data() {
    return {
      particleOptions: { color: '#2DCBFD', linesColor: '#3A71FF' },
      servicesCards: [
        {
          icon: iconTAG,
          title: this.$t("button['image tagging']"),
          introduction: this.$t('services.aiu.TAG'),
          link: 'image-tagging',
        },
        {
          icon: iconCLS,
          title: this.$t("button['image classification']"),
          introduction: this.$t('services.aiu.CLS'),
          link: 'image-classification',
        },
        {
          icon: iconQUA,
          title: this.$t("button['image quality estimation']"),
          introduction: this.$t('services.aiu.QUA'),
          link: 'image-quality-estimation',
        },
        {
          icon: iconOBJ,
          title: this.$t("button['image object detection']"),
          introduction: this.$t('services.aiu.OBJ'),
          link: 'image-object-detection',
        },
        {
          icon: iconSEG,
          title: this.$t("button['image instance segmentation']"),
          introduction: this.$t('services.aiu.SEG'),
          link: 'image-instance-segmentation',
        },
        {
          icon: iconOCR,
          title: this.$t("button['optical character recognition']"),
          languages: [this.$consts.lang.EN, this.$consts.lang.AR],
          introduction: this.$t('services.aiu.OCR'),
          link: 'optical-character-recognition',
        },
        {
          icon: iconLAD,
          title: this.$t("button['image landmark detection']"),
          introduction: this.$t('services.aiu.LAD'),
          link: 'image-landmark-detection',
        },
        {
          icon: iconCBT,
          title: this.$t("button['image celebrity detection']"),
          introduction: this.$t('services.aiu.CBT'),
          link: 'image-celebrity-detection',
        },
        {
          icon: iconCST,
          title: this.$t("button['image clustering']"),
          introduction: this.$t('services.aiu.CST'),
          link: 'image-clustering',
        },
        {
          icon: iconNSFW,
          title: this.$t("button['image not safe for work']"),
          introduction: this.$t('services.aiu.NSFW'),
          link: 'image-not-safe-for-work',
        },
      ],
    };
  },
};
</script>
